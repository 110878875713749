<style lang="scss">
#AdminBookings {
  padding-top: 10px;
}
</style>

<template>
  <div id="AdminBookings" class="container-fluid">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AdminBookings",
};
</script>
